import React, { useState } from 'react'
import styled from 'styled-components'
import useAsyncEffect from 'use-async-effect'
import BackgroundImage from 'gatsby-background-image'
import { get } from 'lodash'
import { Widget } from '@typeform/embed-react'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useStaticQuery, graphql } from 'gatsby'

import media, { breakpoints } from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'

const Container = styled.div`
  display: flex;
  margin-top: 40px;
`
const StyledImageSection = styled(BackgroundImage)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  min-height: 440px;

  ${media.greaterThan('md')`
    height: 95vh;
    position: fixed; 
    overflow-y: auto;
    top: 0; right: 0; bottom: 0; left: 0;
  `}

  ${media.lessThan('md')`
    width: 100%;
    margin-bottom: 24px;
  `}
`
const TypeForm = styled(Widget)`
  width: 500px;
  height: 500px;

  ${media.lessThan('md')`
    width: 400px;
  `}

  ${media.lessThan('sm')`
    width: 300px;
  `}
`

const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  height: 50%;
  gap: 8px;
  margin-top: 24px;

  ${media.lessThan('md')`
    width: 100%;
  `}
`

const AdPlaceImage = ({ placeholderImage }) => {
  return (
    <StyledImageSection
      alt='Programmes immobiliers neufs'
      Tag='div'
      {...convertToBgImage(getImage(placeholderImage))}
      preserveStackingContext>
    </StyledImageSection>
  )
}

const getMinMediaQuery = (size) => {
  if (typeof window !== 'undefined') {
    return window.matchMedia('(min-width: ' + get(breakpoints, size))
  }

  return {
    matches: false,
    addListener: () => { },
    removeListener: () => { }
  }
}

const DemandeAccompagnementContent = () => {
  const [bigScreen, updatebigScreen] = useState(getMinMediaQuery('md').matches)

  useAsyncEffect(async () => {
    const mql = getMinMediaQuery('md')
    const resize = (event) => updatebigScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  })

  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "adPlace/demande-accompagnement.webp"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
  `)

  return (
    <Container>
      <ContainerForm>
        <HomeSectionTitle>Demande d&apos;accompagnement</HomeSectionTitle>
        <TypeForm
          id='EDza5cr4'
          disableScroll={true} />
      </ContainerForm>
      {bigScreen && <AdPlaceImage placeholderImage={placeholderImage} />}
    </Container>
  )
}

export default DemandeAccompagnementContent
