import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

import SEO from '../../../components/atoms/Seo'
import Navbar from '../../../components/organisms/Navbar'
import JsonLD from '../../../components/atoms/JsonLD'
import DemandeAccompagnementContent from '../../../components/organisms/DemandeAccompagnementContent'
import { deepReplace, replaceGraphKey } from '../../../utils/deepReplace'

const DemandeAccompagnement = ({ location }) => {
  const { site } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            webpage {
              _context
              _type
              name
              url
              relatedLink
              specialty
            }
            organization {
              _context
              _type
              _id
              url
              image
              geo {
                _type
                latitude
                longitude
              }
              address {
                _type
                streetAddress
                addressLocality
                postalCode
                addressCountry {
                  _type
                  name
                }
              }
              name
              knowsAbout
            }
          }
        }
      }
    `)

  const keywords = ['Locaux', 'Libéral', 'Professionnel', 'Rendez-vous', 'experts']
  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: 'TrouverMonLocalPro - Rendez-vous avec un expert',
    description: '',
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: keywords.join(', '),
    makesOffer: {
      '@type': 'Offer'
    }
  }

  const { webpage, organization } = get(deepReplace(site, replaceGraphKey), 'siteMetadata')
  const title = 'Demande accompagnement - Locaux professions libérales'
  const description = 'Demande d\'accompagnement pour trouver un local professionnel. Acheter un local, Vendre un local, Louer un local.'

  return (
    <>
      <SEO
        title={title}
        description={description}>
        <JsonLD>
          {[webpage, organization, linkedData]}
        </JsonLD>
      </SEO>
      <Navbar location={location} />
      <DemandeAccompagnementContent />
    </>
  )
}

DemandeAccompagnement.propTypes = {
  location: PropTypes.object.isRequired
}

export default DemandeAccompagnement
